<template>
  <div class="error-page">
    <div class="errorWarp">
      <div class="image">
        <img src="../../assets/404_03.png"/>
      </div>
      <h2 class="title">您访问的页面不存在……</h2>
      <h3 class="desc"></h3>
      <router-link to="/Home" class="backBtn" tag="span">返回首页</router-link>
    </div>
  </div>
</template>
<script>
export default{
  data() {
    return {
      
    }
  }
}
</script>
<style scoped>
  @import "./error.less";
</style>
